<template>
  <div class="inner111">
    <p class="title">
      <span>查看详情</span>
      <span @click="close" class="pointer">
        <i class="el-icon-circle-close"></i>
      </span>
    </p>
    <div class="modal_item">
      <div style="width:360px">
           <el-divider
        content-position="left"
        >基本信息</el-divider>
      <p>
        <span class="spa">申请人</span
        ><span>{{ form.userName }} · {{ form.createTime }}</span>
      </p>
      <p>
        <span class="spa">材料名称</span><span>{{ form.productName }}</span>
      </p>
      <p>
        <span class="spa">供应商</span><span>{{ form.merchantName }}</span>
      </p>
       <p>
        <span class="spa">摘要</span><span>{{ form.summary }}</span>
      </p>
      
      <div>

      </div>

      <el-divider
        content-position="left"
        >采购明细</el-divider>
      <div class="jstitle">
             <span style="color:#000">名称</span>
             <span style="color:#000">数量</span>
             <span style="color:#000">单价</span>
             <span style="color:#000">小计</span>
             <span style="color:#000" v-if="form.type==1">库存</span>
          </div>
          <div class="jstitle" v-for="item in form.procureDetail" :key='item.name'>
             <span >{{item.name}}</span>
             <span>{{item.quantity}}</span>
             <span>{{item.unit}}</span>
             <span>{{item.unit*item.quantity}}</span>
             <span v-if="form.type==1">{{item.stock}}</span>
          </div>
          <p>
        <span class="spa">运费</span><span>{{ form.freight }}</span>
      </p>
      <p>
        <span class="spa">发票</span
        ><span>
          <span v-if="form.bill == '1'">无发票</span>
          <span v-else-if="form.bill == '2'">普通发票</span>
          <span v-else-if="form.bill == '3'">增值税专用发票</span>
         <span v-if="form.bill != '1'"> · {{ form.taxRate }}%</span>
        </span>
      </p>
      
      <el-divider
        v-show="srcList.length!=0"
        content-position="left"
        >采购合同</el-divider>
      <p v-if='srcList.length>0'>
        <!-- <span class="spa">采购合同</span> -->
        <span v-for="item in srcList"
          :key="item">
          <span v-if="item.indexOf('.pdf')!=-1" style=" cursor: pointer;
          color: #0090ff;"  @click="downLoad(item)">{{item}}</span>
           <el-image    
           v-else    
          style="width: 80px; height: 100px; margin-right: 20px"
          :src="item"
          :preview-src-list="srcList">
        </el-image>
        <span></span>
        </span>
       
      </p>

      <p style="display: flex" v-show="srcList.length==0&&userid.sss==10">
              <span class="spa">采购合同</span>
              <el-upload
                class="upload-demo"
                :action="axiosUrl + '/admin/l/file/uploadPicture'"
                :on-success="handlePreview"
                :on-remove="handleRemove"
                :file-list="fileList"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </p>

      </div>
      <div style="width:360px">
              <el-divider
        content-position="left"
        >款项约定</el-divider>
      <p>
        <span class="spa">付款方式</span
        ><span v-if="form.settlement == '1'">先款后货</span>
        <span v-else-if="form.settlement == '2'">先货后款</span>
      </p>
      <p v-show="form.settlement == 1">
        <span class="spa">首付比例</span><span>{{ form.paymentRatio }}%</span>
      </p>
      <p v-show="form.settlement == 1">
        <span class="spa">首付金额</span><span>{{ form.payment }}</span>
      </p>
      <!-- <p>
        <span class="spa">计划</span
        ><span>{{ form.planUser }}·{{ form.planTime }}</span>
      </p> -->
      <p>
        <span class="spa">订单总价</span><span class="colorred">{{ form.total }}</span>
      </p>
      <p>
        <span class="spa">剩余尾款</span><span class="colorred">{{ form.balance }}</span>
      </p> 
      <!-- <p>
        <span class="spa">支付方式</span><span v-if="form.payType == '1'">现金</span>
        <span v-else-if="form.payType == '2'">电子汇款</span>
        <span v-else-if="form.payType == '3'">对公账户</span>
      </p>
      <p>
        <span class="spa">支付账户</span><span>{{ form.bankAccount }}</span>
      </p> -->
                <el-divider
        v-if="form.payList"
        content-position="left">{{form.balanceState==3?'报销详情':'付款详情'}}
        </el-divider>
      <div class="item_describe" v-show="form.payList!='null'">
        <div class="describe_right">
          <div
            class="spa_blue"
            v-for="(item, index) in form.payList"
            :key="index + 10"
          >
            <p>
              <span class="spa">支付方式</span
              ><span>
                <span v-if="item.type == '1'">现金</span>
                <span v-else-if="item.type == '2'">电子汇款</span>
                <span v-else-if="item.type == '3'">对公账户</span>
              </span>
            </p>
            <p>
              <span class="spa">银行名称</span><span>{{ item.name }}</span>
            </p>
            <p>
              <span class="spa">银行账户</span><span>{{ item.account.substr(0,4) }}****{{ item.account.substr(-4) }}</span>
            </p>
            <p>
              <span class="spa">金额</span><span>{{ item.amount }}</span>
            </p>
            <p>
              <span class="spa">支付时间</span><span>{{ item.sdatatime }}</span>
            </p>
            <div style="margin-bottom: 12px">
              <span class="spa">支付凭证</span>
              <el-image
                v-for="url in item.formFile"
                :key="url"
                style="width: 120px; height: 80px; vertical-align: text-top"
                fit="contain"
                :src="url"
                :preview-src-list="item.formFile"
              >
              </el-image>
            </div>
            <div class="line" style="border-top: 1px dashed rgb(221, 214, 214);
            width: 340px;margin:8px 0 10px 0"></div>
          </div>
        </div>
      </div>
      <!-- <div class="item_describe" v-show="form.state == 2 && userid.state == 2">
        <div class="describe_right" v-if="form.settlement == '1'">
          <p>
            <span class="spa">支付方式</span
            ><span>
              <el-select
                v-model="zfForm.type"
                @change="zhChange"
                size="mini"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in option4"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </span>
          </p>
          <p v-show="zfForm.type != 1">
            <span class="spa">银行帐户</span>
            <el-select
              v-model="zfForm.bankAccountId"
              size="mini"
              placeholder="请选择"
            >
              <el-option
                v-for="item in option3"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
                <span
                  style="margin-right: 12px; color: #333; font-size: 12px"
                  >{{ item.name }}</span
                >
                <span style="margin-right: 12px; color: #333; font-size: 12px"
                  >(****{{ item.account.substr(-4) }})</span
                >
              </el-option>
            </el-select>
          </p>
          <p>
            <span class="spa">支付金额</span
            ><el-input v-model="zfForm.amount" size="mini"></el-input>
          </p>
          <p style="display: flex">
            <span class="spaa"><span class="star">* </span>支付凭证</span>
            <el-upload
              class="upload-demo"
              :action="axiosUrl + '/admin/l/file/uploadPicture'"
              :on-success="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </p>
        </div>
      </div> -->
      <p v-show="form.state == 3 || form.state == 4">
        <span class="spa">审批意见</span>
        <el-input type="text" size="mini" v-model="form.describe"></el-input>
      </p>

      </div>
   


    </div>
          <div class="item_center1" v-show="form.state == 3 &&userid.sss==3">
        <button class="passbtn" @click="auditInfo('1')">通过</button>
        <button class="refusebtn" @click="auditInfo('2')">驳回</button>
      </div>
        <div class="item_center1" v-show="srcList.length==0&&userid.sss==10">
          <button
            class="passbtn"
            @click="payAddF1()">
            提交合同
          </button>
      </div>
  </div>
</template>

<script>
import {
  procureInfo,
  procureExamine,
  procureFillContractFile,
  axiosUrl,
  getListByPay,
} from "@/api/apis.js";
export default {
  data() {
    return {
      //地图实例
      form: { payList: [] },
      formFile: [],
      fileList: [],
      axiosUrl,
      iId:'',
      permissoin:[],
      srcList: [],
      option3: [],
      zfForm: {},
      option4: [
        {
          value: 1,
          label: "现金支付",
        },
        {
          value: 2,
          label: "电子汇款",
        },
        {
          value: 3,
          label: "对公账户",
        },
      ],
    };
  },
  props: ["userid"],
  methods: {
    close() {
      this.$emit("close");

      this.form = {};
    },
    downLoad(item){
        // location.href=item
        window.open(item,'_blank');
    },
    //进行审批
    auditInfo(num) {
      let data = {
        state: num,
        describe: this.form.describe,
        id: this.form.id,
      };
      procureExamine(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.$emit("get");
        } else {
          this.$message.error("操作失败");
        }
        this.close();
      });
    },
    zhChange(i) {
      let data = { type: i - 1 };
      getListByPay(data).then((res) => {
        if (res.code == 200) {
          this.option3 = res.data;
        } else {
          this.option3 = [];
        }
      });
    },
    //上传文件
    handleRemove(file, fileList) {
      let str = "";
      fileList.forEach((item) => {
        str += item.response.data.url + ",";
      });
      this.zfForm.file = str.slice(0, -1);
    },
    handlePreview(response, file, fileList) {
      let str = "";
      if (response.code == 200) {
        fileList.forEach((item) => {
          str += item.response.data + ",";
        });
        this.zfForm.file = str.slice(0, -1);
      }
    },
    payAddF1(){
        let data={
          id: this.form.id,
          contractFile:this.zfForm.file 
        }
        procureFillContractFile(data).then(res=>{
          if (res.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.close();
          this.$emit("get");
        } else {
          this.$message.error(res.data.msg);
        }
        
        })
    }
  },
  mounted() {
    let arr = JSON.parse(localStorage.getItem("perArr"));
     this.iId= localStorage.getItem("ld"); 
      let routePath = this.$route.path;
    arr.forEach((item) => {
      if (item.purl == routePath) {
        this.permissoin.push(item.permission);
      }
    });
    let data = { id: this.userid.id };
    procureInfo(data).then((res) => {
      if (res.code == 200) {
        this.form = res.data;
        if (res.data.contractFile) {
          this.srcList = res.data.contractFile.split(",");
          // this.srcList.forEach(item=>{
          //   console.log(item.indexOf('.pdf'));
            
          // })
        } else {
          this.srcList = [];
        }
        if (res.data.payList) {
          res.data.payList.map((item) => {
            if (item.file) {
              item.formFile = item.file.split(",");
            } else {
              item.formFile = [];
            }
          });
        }
      } else {
        this.$message.error(res.data.msg);
      }
    });
  },

  beforeDestroy() {
    this.form = {};
    this.zfForm = {};
  },
};
</script>

<style lang="less">
.inner111 {
  width: 900px;
  max-height: 800px;
  box-sizing: border-box;
  background-color: white;
  margin: auto;
  box-shadow: 1px 1px 10px #888888;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  position: relative;
  .title {
    color: #2b2e36;
    line-height: 50px;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    margin-bottom: 10px;
    border-bottom: 1px solid #dddddd;
    i {
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
    }
  }
  .modal_item {
    padding: 0 30px;
    height: 700px;
    display: flex;
    justify-content: space-around;
    overflow: auto;
    color: #999;
    &::-webkit-scrollbar {
      width: 0px;
    }
    .spa {
      display: inline-block;
      width: 75px;
      color: rgba(0, 0, 0);
    }
    .spa_green {
      color: #15bb9b;
      font-weight: bold;
    }

    .spaa {
      display: inline-block;
      width: 83px;
      margin-left: -9px;
      color: rgba(0, 0, 0);
    }
    .star {
      color: #ff2825;
      font-size: 14px;
      position: relative;
      top: 2px;
    }
    p {
      display: flex;
      margin-bottom: 22px;
      .el-input {
        width: 300px;
      }
    }
    // .seat{
    //   width: auto;
    //   height: 1px;
    //   background-color: #ddd;
    //   margin-bottom: 10px;
    // }
    .item_center {
      display: flex;
      margin-bottom: 22px;
      .spa {
        display: inline-block;
        width: 75px;
      }
      .el-input {
        width: 300px;
      }
    }
    .jstitle{
      margin-bottom: 12px;
          span{
            display: inline-block;
            margin-right: 10px;
          }
          &>span:nth-child(1){
            width: 100px;
          }
          &>span:nth-child(2){
            width: 50px;
          }
          &>span:nth-child(3){
            width: 50px;
          }
           &>span:nth-child(4){
            width: 50px;
          }
           &>span:nth-child(5){
            width: 50px;
          }

        }

    .item_describe {
      // height: 216px;
      margin-bottom: 16px;
      overflow: auto;
      flex-wrap: wrap;
      .describe_right {
        .spa_blue {
          color: #0090ff;
          cursor: pointer;
        }
      }
    }
  }
      .item_center1 {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      .spa {
        display: inline-block;
        width: 75px;
      }
      .el-input {
        width: 200px;
      }
          .passbtn {
      width: 180px;
      height: 35px;
      background-color: #141414;
      color: #fff;
      border-radius: 7px;
      cursor: pointer;
    }
    .refusebtn {
      width: 180px;
      height: 35px;
      background-color: #fe0000;
      color: #fff;
      border-radius: 7px;
      margin-left: 50px;
      cursor: pointer;
    }
    }
  .foot {
    font-size: 12px;
    margin-top: 18px;
    padding: 0 30px;
    display: flex;
    justify-content: center;
    .passbtn {
      margin-right: 20px;
    }
  }
}
</style>
<template>
  <div class="br11222" ref="vlbox">
    <div class="index_main" ref="index_main">
      <div class="index_right">
        <p class="search">
          <el-select
            v-model="searchForm.state"
            v-if="permissoin.includes('query')"
            size="mini"
            clearable
          >
            <el-option
              v-for="item in option1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input
            v-if="permissoin.includes('query')"
            type="text"
            clearable
            size="mini"
            v-model="searchForm.name"
            placeholder=" 请输入关键字"
          ></el-input>
          <button
            class="btn1"
            v-if="permissoin.includes('query')"
            @click="
              currentPage2 = 1;
              getData();
            "
          >
            <i class="el-icon-search"></i>
            查询
          </button>
          <button
            class="btn2"
            v-if="permissoin.includes('add')"
            @click="modal = 1"
          >
            <i class="el-icon-plus"></i>
            新增
          </button>
        </p>
        <div class="table srcollstyle">
          <el-table :data="tableData" style="width: 98%" border>
            <el-table-column type="index" label="排序" width="80">
              <template slot-scope="scope">
                {{ (currentPage2 - 1) * 10 + scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column
              prop="productName"
              label="材料名称"
              width="240"
              :show-overflow-tooltip="true"
            >
            <!-- <template slot-scope="scope">
              <span
                @click="handleCommand(scope.row)"
                class="colorblue"
                style="cursor: pointer"
                >{{ scope.row.productName }}</span
              >
            </template> -->
            </el-table-column>
            <!-- <el-table-column prop="quantity" label="材料数量">
            </el-table-column> -->
            <el-table-column prop="name" label="材料类型">
              <template slot-scope="scope">
                <span v-if="scope.row.type == 1">硬件材料</span>
                <span v-if="scope.row.type == 2">办公用品</span>
                <span v-if="scope.row.type == 3">研发服务</span>
                <span v-if="scope.row.type == 4">生产物资工具</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="merchantName"
              label="供应商"
              width="200"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column prop="userName" label="申请人"> </el-table-column>
            <el-table-column prop="createTime" label="申请日期">
            </el-table-column>
             <el-table-column prop="total" label="采购总价">
               <template slot-scope="scope">
                  <span class="colorred">￥{{fmoney(scope.row.total,2)}}</span>
                    </template>
            </el-table-column>
            <el-table-column  label="结算方式">
              <template slot-scope="scope">
                <span class="colorblue" v-if="scope.row.settlement == 2">先货后款（<span >货</span>）</span>
                <span v-if="scope.row.settlement == 1">先款后货（款）</span>
              </template>
               </el-table-column>
                <el-table-column  label="发票状态" width="168">

              <template slot-scope="scope">
                <span v-if="scope.row.bill == 1">无发票</span>
                <span v-if="scope.row.bill == 2">普通发票</span>
                <span v-if="scope.row.bill == 3">专用发票</span>  <span v-show="scope.row.bill!=1"> · {{scope.row.taxRate}}%</span> 
                <span v-if="scope.row.invoiceAnnex == 2&&scope.row.bill!=1" class="colorgreen">（已上传）</span>
                <span v-if="scope.row.invoiceAnnex == 1&&scope.row.bill!=1" class="colorred">（未上传）</span>
              </template>
               </el-table-column>

                <el-table-column prop="userName" label="合同状态">
              <template slot-scope="scope">
                <span v-if="scope.row.contractFile == 2" class="colorgreen">有合同</span>
                <span v-if="scope.row.contractFile == 1">无合同</span>
              </template>
               </el-table-column>
            <el-table-column label="计划状态"  width="128">
              <template slot-scope="scope">
                <span v-if="scope.row.state == 1" class="colorgreen"
                  >已通过</span>
                <span v-if="scope.row.state == 3" class="colororange"
                  >待审核</span>
                <span v-if="scope.row.state == 4" class="colorred">驳回</span>
                <span v-if="scope.row.state == 5" class="colorgreen"
                  >已结清</span>
                <span v-if="scope.row.balanceState == 2">（有尾款）</span>
              </template>
            </el-table-column>
             <el-table-column label="操作">
              <template slot-scope="scope">
                <el-dropdown trigger="click" @command="handleCommand">
                  <el-button type="primary" plain size="small">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-if="permissoin.includes('query')"
                      icon="el-icon-search"
                      :command="{ num: 20, data: scope.row }"
                      >查看</el-dropdown-item>
                    <el-dropdown-item
                      icon="el-icon-edit-outline"
                      v-if="scope.row.state == 3 && permissoin.includes('examine')"
                      :command="{ num: 3, data: scope.row }"
                      >审核</el-dropdown-item>
                    <el-dropdown-item
                    icon="el-icon-upload2" 
                    v-if="scope.row.state != 4&&iId==scope.row.userId&&scope.row.contractFile == 1"
                      :command="{ num: 10, data: scope.row }"
                      >上传合同</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage2"
            prev-text="上一页"
            next-text="下一页"
            :page-size="pageSize"
            layout="total,sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="modal" v-show="modal == 1">
      <div class="inner">
        <p class="title">
          <span>新增采购</span>
          <span
            @click="
              modal = 0;
              form = {sms:'0',settlement:'1'};
              netArrUpdate = [{}];
              payment = 0;
              paymentRatio = 100;fileList=[]
            "
            class="pointer"
          >
            <i class="el-icon-close"></i>
          </span>
        </p>
        <div class="modal_center scrollbar_width0">
          <div style="margin-right: 70px">
            <p>
              <span class="spaa"><span class="star">* </span>采购类型</span>
              <el-select v-model="form.type" size="mini" placeholder="请选择">
                <el-option
                  v-for="item in option2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </p>
            <p>
              <span class="spaa"><span class="star">* </span>供应商</span>
              <el-select
                v-model="form.merchantId"
                clearable
                filterable
                size="mini"
                placeholder="请选择">
                <el-option
                  v-for="item in option3"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </p>
            <p>
              <span class="spa">发票类型</span>
              <el-select v-model="form.bill" size="mini" placeholder="请选择">
                <el-option
                  v-for="item in option4"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </p>
            <p v-show="form.bill != 1">
              <span class="spa">税率</span>
              <el-select
                v-model="form.taxRate"
                size="mini"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in option40"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </p>
            <p>
              <span class="spa">摘要</span>
              <el-input
                v-model="form.summary"
                placeholder="请输入"
                size="mini"
              ></el-input>
            </p>
            <p>
              <span class="spa">结算方式</span>
              <el-radio-group v-model="form.settlement" size="mini">
                <el-radio-button label="1">先款后货</el-radio-button>
                <el-radio-button label="2">先货后款</el-radio-button>
              </el-radio-group>
            </p>
            <p v-show="form.settlement==1">
              <span class="spaa"><span class="star">* </span>首付比例</span>
              <el-input v-model="form.paymentRatio" size="mini">
              <template slot="append">%</template>
              </el-input>
            </p>
            <p v-show="form.settlement==1" class="disabledred">
              <span class="spaa"><span class="star">* </span>首付金额</span>
              <el-input disabled v-model="form.payment" size="mini">
              <!-- <template slot="append">元</template> -->
              </el-input>
            </p>
            <p>
              <span class="spaa"><span class="star">* </span>运费</span>
              <el-input v-model="form.freight" size="mini"></el-input>
            </p>
            <p class="disabledred">
              <span class="spaa"><span class="star">* </span>订单总价</span>
              <el-input disabled v-model="form.name" size="mini"></el-input>
            </p>
            <p>
              <span class="spa">短信提示</span>
              <el-radio-group v-model="form.sms" size="mini">
                <el-radio-button label="1">发送短信</el-radio-button>
                <el-radio-button label="0">不发送</el-radio-button>
              </el-radio-group>
            </p>
          </div>
          <div>
            <div class="text_item">
              <span>*</span>采购明细
              <span @click="addFormUpdateItem">
                <i class="el-icon-plus"></i>
                增加名目</span
              >
            </div>
            <div class="jstitle">
              <span>名称</span>
              <span>数量</span>
              <span>单价（元）</span>
            </div>
            <div
              class="net"
              v-for="(item, index) in netArrUpdate"
              :key="index + 100"
            >
              <el-select
                clearable
                filterable
                v-model="item.name"
                size="mini"
                value-key='id'
                placeholder="请选择"
                v-if="form.type == 1"
              >
                <el-option
                  v-for="item in option5"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
              <el-input
                v-else
                v-model="item.name"
                placeholder="请输入"
                size="mini"
              ></el-input>             
              <el-input
                v-model="item.quantity"
                placeholder="请输入"
                size="mini"
              ></el-input>
              <el-input
                v-model="item.unit"
                placeholder="请输入"
                size="mini"
              ></el-input>
              <div style="display: flex">
                <p class="line"></p>
                <span
                  style="
                    cursor: pointer;
                    position: relative;
                    top: -6px;
                    color: #d62829;
                  "
                  @click="deletenvritem(index)"
                  v-show="index > 0"
                  ><i class="el-icon-close">删除</i></span
                >
              </div>
            </div>

            <p style="display: flex">
              <span class="spa">采购合同</span>
              <el-upload
                class="upload-demo"
                :action="axiosUrl + '/admin/l/file/uploadPicture'"
                :on-success="handlePreview"
                :on-remove="handleRemove"
                :file-list="fileList"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </p>
          </div>
        </div>
        <div class="bottom">
          <button class="btn_red" @click="onsubmit">保存</button>
          <button
            class="btn_gray"
            @click="
              modal = 0;
              form = {};
              netArrUpdate = [{}];
              payment = 0;
              paymentRatio = 1;">
            取消
          </button>
        </div>
      </div>
    </div>
    <div class="modal" v-if="modal == 2">
      <PA :userid="csdata" @close="close" @get="getData"></PA>
    </div>
  </div>
</template>

<script>
import {
  procureGetList,
  axiosUrl,
  procureAdd,
  productGetList,
  merchantGetList,
} from "@/api/apis.js";
import PA from "@/components/Procurement_approval.vue";
export default {
  data() {
    return {
      options: [],
      axiosUrl,
      csdata: {},
      form: { state: 0 ,sms:'0',settlement:'1',paymentRatio:100,payment:0},
      info: 1,
      currentPage2: 1,
      treeData: [],
      hide: 1,
      modal: 0,
      treeList: [],
      fileList: [],
      total: 0,
      searchForm: {},
      permissoin: [],
      pageSize: 10,
      //供应商
      option3: [],
      //产品
      option5: [],
      option41: [
        { name: "现金", id: 1 },
        { name: "电子汇款", id: 2 },
        { name: "对公账户", id: 3 },
      ],
      paymentRatio: 100,
      payment: 0,
      ruleForm: {
        pwd: 123456,
      },
      props: {
        label: "name",
        value: "id",
        children: "children",
        checkStrictly: true,
      },
      netArrUpdate: [{}],
      option40: [
        { name: "1%", id: 1 },
        { name: "3%", id: 3 },
        { name: "6%", id: 6 },
        { name: "9%", id: 9 },
        { name: "13%", id: 13 },
      ],
      option1: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "已通过",
        },
        // {
        //   value: "2",
        //   label: "待付款",
        // },
        {
          value: "3",
          label: "待审核",
        },
        {
          value: "4",
          label: "驳回",
        },
        {
          value: "5",
          label: "已结清",
        },
      ],
      option2: [
        {
          value: "1",
          label: "硬件材料",
        },
        {
          value: "2",
          label: "办公用品",
        },
        {
          value: "3",
          label: "研发服务",
        },
        {
          value: "4",
          label: "生产物资工具",
        },
      ],
      iId:'',
      option4: [
        {
          value: "1",
          label: "无发票",
        },
        {
          value: "2",
          label: "普通发票",
        },
        {
          value: "3",
          label: "增值税专用发票",
        },
      ],
      tableData: [],
      rules: {
        orgId: [{ required: true, message: "选择机构", trigger: "blur" }],
      },
    };
  },
  components: {
    PA,
  },
  methods: {
    getData() {
      let data = {
        state: this.searchForm.state,
        name: this.searchForm.name,
        pageNum: this.currentPage2,
        pageSize: this.pageSize,
      };
      procureGetList(data).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total - 0;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
    addFormUpdateItem() {
      this.$set(this.netArrUpdate, this.netArrUpdate.length, {});
    },
    deletenvritem(index) {
      this.netArrUpdate.splice(index, 1);
    },
    close() {
      this.modal = 0;
    },

    handleCurrentChange(val) {
      this.currentPage2 = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
         fmoney(val) {
      if(val){
 //金额转换 分->元 保留2位小数 并每隔3位用逗号分开 1,234.56
        var str = val.toFixed(2) + '';
        var intSum = str.substring(0,str.indexOf(".")).replace( /\B(?=(?:\d{3})+$)/g, ',' );//取到整数部分
        var dot = str.substring(str.length,str.indexOf("."))//取到小数部分搜索
        var ret = intSum + dot;
        return ret;
      }else{
        return '0'
      }    
    },
    //上传文件
    handleRemove(file, fileList) {
      let str = "";
      fileList.forEach((item) => {
        str += item.response.data.url + ",";
      });
      this.form.contractFile = str.slice(0, -1);
    },
    handlePreview(response, file, fileList) {
      let str = "";
      if (response.code == 200) {
        fileList.forEach((item) => {
          str += item.response.data + ",";
        });
        this.form.contractFile = str.slice(0, -1);
      }
    },
    onsubmit() {
      let data = {};
      let arr=[]
      let flag =true
      if(!this.netArrUpdate[0].name){
        flag=false
        this.$message.error('请填写采购明细')
      }
      if(flag){
         if(this.form.type==1){
        arr=this.netArrUpdate.map(item=>{
          let obj={
            name:item.name.name,
            stock:item.name.stock,
            productId:item.name.id,
            quantity:item.quantity,
            unit:item.unit,
          }         
          return obj
        })
      }else{
        arr=this.netArrUpdate
      }
      if (this.form.settlement == 1) {
        data = {
          type: this.form.type,
          bill: this.form.bill,
          merchantId: this.form.merchantId,
          taxRate: this.form.taxRate,
          freight: this.form.freight,
          summary: this.form.summary,
          contractFile: this.form.contractFile,
          settlement: this.form.settlement,
          paymentRatio: this.form.paymentRatio,
          payment: this.form.payment,
          procureDetail: JSON.stringify(arr),
          total: this.form.name,
          sms: this.form.sms,
        };
      } else {
        data = {
          type: this.form.type,
          merchantId: this.form.merchantId,
          bill: this.form.bill,
          taxRate: this.form.taxRate,
          summary: this.form.summary,
          freight: this.form.freight,
          contractFile: this.form.contractFile,
          settlement: this.form.settlement,
          procureDetail: JSON.stringify(arr),
          total: this.form.name,
        };
      }
      procureAdd(data).then((res) => {
        if (res.code == 200) {
          this.getData();
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.modal = 0;
          this.payment = 0;
          this.paymentRatio = 1;
          this.form = {sms:'0',settlement:'1'};
          this.netArrUpdate = [{}];
          this.fileList=[]
        } else {
          this.$message.error(res.data.msg);
        }
      });
      }
     
    },
    handleCommand(d) {
      this.form.id = d.data.id;
      this.csdata = { id: d.data.id, state: d.data.state,sss:d.num };
      this.modal = 2;
    },
  },
  mounted() {
    //获取权限
    let arr = JSON.parse(localStorage.getItem("perArr"));
    this.iId= localStorage.getItem("ld"); 
    let routePath = this.$route.path;
    arr.forEach((item) => {
      if (item.purl == routePath) {
        this.permissoin.push(item.permission);
      }
    });
    this.getData();
    merchantGetList({ pageSize: 0 }).then((res) => {
      if (res.code == 200) {
        this.option3 = res.data.list.filter((item) => item.type == 1);
      }
    });
    productGetList({ pageSize: 0 }).then((res) => {
      if (res.code == 200) {
        this.option5 = res.data.list.filter((item) => item.type == 1);
      }
    });
  },
  watch: {
    form: {
      deep: true, //深度监听
      handler: function () {
        let num1 = 0;
        this.netArrUpdate.forEach((item) => {
          num1 += (item.quantity - 0) * (item.unit - 0);
        });
        this.form.name =
          (isNaN(num1) ? 0 : num1) +
          ((isNaN(this.form.freight) ? 0 : this.form.freight) - 0);
        // this.sfje();
        this.form.payment=(isNaN(num1)?0:num1)*((isNaN(this.form.paymentRatio)?0:this.form.paymentRatio)/100)
      },
    },
     netArrUpdate: {
      deep: true, //深度监听
      handler: function () {
          let num1=0
        this.netArrUpdate.forEach((item)=>{
          num1+=(item.quantity-0)*(item.unit-0)
        })
        this.form.name=(isNaN(num1)?0:num1)+
          ((isNaN(this.form.freight) ? 0 : this.form.freight) - 0);
        // this.sfje();
        this.form.payment=(isNaN(num1)?0:num1)*((isNaN(this.form.paymentRatio)?0:this.form.paymentRatio)/100)
      },
    },
  },
};
</script>

<style lang="less" >
.br11222 {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .index_main {
    flex: 1;
    background-color: #fff;
    display: flex;
  }
  .index_right {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 28px;
    box-sizing: border-box;
    .search {
      margin: 22px 0;
      .el-input {
        width: 208px;
        margin-right: 20px;
      }
    }
    .table {
      flex: 1;
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide {
      position: absolute;
      top: 48%;
      left: 0px;
      cursor: pointer;
    }
  }
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    .inner {
      width: 900px;
      // max-height: 800px;
      height: 700px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 4px #888888;
      .title {
        color: #2b2e36;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #f1f0f0;
        i {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .modal_center {
        flex: 1;
        padding: 0 34px;
        box-sizing: border-box;
        padding-top: 20px;
        display: flex;
        .el-button--mini,
        .el-button--mini.is-round {
          padding: 6px 12px;
          margin-left: 4px;
        }
        .el-input-number {
          .el-input {
            width: 130px;
          }
        }
        .sel {
          display: flex;
          margin-bottom: 14px;
          line-height: 26px;
          .el-input__inner {
            width: 200px;
          }
          .el-select .el-input {
            width: 200px;
          }
        }
        .blue {
          color: #0090ff !important;
          font-weight: bold;
        }
        .red {
          color: #ff2825 !important;
          font-weight: bold;
        }
        .manyitem {
          color: #000;
          margin-bottom: 14px;

          .title1 {
            display: flex;
            justify-content: space-between;
            padding-right: 20px;
            font-size: 14px;
            p {
              font-weight: bold;
            }
          }
        }
        .el-table th {
          background-color: white;
          padding: 0px;
        }
        .twoitem {
          display: flex;
          justify-content: space-between;
          margin-bottom: 14px;
        }
        .spaa {
          display: inline-block;
          width: 83px;
          margin-left: -9px;
          color: rgba(0, 0, 0);
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
        .spa {
          display: inline-block;
          width: 75px;
          color: rgba(0, 0, 0);
        }
        .text_item {
          margin-bottom: 12px;
          & > span:nth-child(1) {
            color: #f56c6c;
            margin-right: 2px;
          }
          & > span:nth-child(2) {
            display: inline-block;
            margin-left: 198px;
            cursor: pointer;
            color: #d62829;
            i {
              font-weight: 600;
              font-size: 14px;
            }
          }
        }
        .jstitle {
          span {
            display: inline-block;
            margin-right: 20px;
          }
          & > span:nth-child(1) {
            width: 100px;
          }
          & > span:nth-child(2) {
            width: 100px;
          }
          & > span:nth-child(3) {
            width: 100px;
          }
        }
        .net {
          .line {
            border-top: 1px dashed rgb(221, 214, 214);
            width: 348px;
          }
          p {
            margin-bottom: 20px;
          }
          .spaa {
            display: inline-block;
            width: 80px;
          }
          .el-select {
            width: 100px;
            margin-right: 20px;
            .el-input {
              width: 100px;
            }
          }
          .el-input {
            width: 100px;
            margin-right: 20px;
            .el-input__inner {
              border: none;
              padding: 0;
            }
          }
          .star {
            color: #ff2825;
            font-size: 14px;
            position: relative;
            top: 2px;
          }
        }

        .line {
          border-top: 1px dashed rgb(221, 214, 214);
          width: 348px;
        }
        p {
          margin-bottom: 20px;
        }
        .spaa {
          display: inline-block;
          width: 80px;
        }
        .el-input {
          width: 270px;
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
      }
      .bottom {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        & > button:nth-child(1) {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>